import $ from 'jquery';
import IMask from 'imask';
import { DateTime, TempusDominus } from '@eonasdan/tempus-dominus';
const placeholders = {
    en: {
        d: 'D',
        m: 'М',
        y: 'Y',
    },
    lv: {
        d: 'D',
        m: 'М',
        y: 'G',
    },
    ru: {
        d: 'Д',
        m: 'М',
        y: 'Г',
    },
};
export default class DateInput {
    static init() {
        const $dateInput = $('.js__date-input');
        const locale = $dateInput.data('locale') || 'en';
        const maskOptions = {
            mask: 'dd.mm.YYYY',
            lazy: false,
            overwrite: true,
            autofix: true,
            blocks: {
                dd: { mask: IMask.MaskedRange, placeholderChar: placeholders[locale].d, from: 1, to: 31, maxLength: 2 },
                mm: { mask: IMask.MaskedRange, placeholderChar: placeholders[locale].m, from: 1, to: 12, maxLength: 2 },
                YYYY: { mask: IMask.MaskedRange, placeholderChar: placeholders[locale].y, from: 1900, to: 2999, maxLength: 4 },
            },
        };
        $.each($dateInput, (index, element) => {
            const $input = $(element);
            const $dateInputWrapper = $input.closest('.js__date-picker');
            const $minDate = $input.data('min-date');
            const $maxDate = $input.data('max-date');
            if ($dateInputWrapper.length) {
                IMask(element, maskOptions);
                new TempusDominus($dateInputWrapper[0], {
                    display: {
                        components: {
                            clock: false,
                        },
                        icons: {
                            type: 'icons',
                            up: 'nbw-icon nbw-icon-arrow-up',
                            down: 'nbw-icon nbw-icon-arrow-down',
                            previous: 'nbw-icon nbw-icon-arrow-left',
                            next: 'nbw-icon nbw-icon-arrow-right',
                            clear: 'nbw-icon nbw-icon-minus',
                            close: 'nbw-icon nbw-icon-close',
                        },
                        buttons: {
                            close: true,
                        },
                    },
                    localization: {
                        format: 'dd.MM.yyyy',
                        locale: locale,
                    },
                    restrictions: {
                        minDate: $minDate ? new DateTime(new Date($minDate)) : undefined,
                        maxDate: $maxDate ? new DateTime(new Date($maxDate)) : undefined,
                    },
                });
                this.addDatePickerEvents($dateInputWrapper, $input);
            }
            else {
                IMask(element, maskOptions);
            }
        });
        this.addEmptyStyle($dateInput);
        $dateInput.on('keyup', () => {
            this.addEmptyStyle($dateInput);
        });
    }
    static isEmptyValue(val) {
        const emptyValue = 'DD.MM.YYYY';
        return val === emptyValue;
    }
    static addEmptyStyle($input) {
        $input.toggleClass('is-empty', this.isEmptyValue($input.val()));
    }
    static addDatePickerEvents($dateInputWrapper, $input) {
        $dateInputWrapper.on('change.td', () => {
            $input.trigger('keyup');
        });
    }
}
